<template>
    <div class="goods_list_container">
        <!-- 分类路径 -->
        <div>
            <CategoryHeader @getCate="getCate"></CategoryHeader>
        </div>
        <div class="goods_list_banner">
            <!-- 二级分类或者三级分类的组件 -->
            <GoodsListCate3 :categoryid="categoryId3" v-if="showIndex === 3" @attriOption="attriOption"
                @brandOption="brandOption" ref="goodsCate3"></GoodsListCate3>
            <GoodsListCate2 :cateItem="cate1.child" :cate2Name="cate1.name"
                v-else-if="showIndex == 1 || showIndex == 2">
            </GoodsListCate2>

        </div>

        <div class="goods_list clearfix">
            <!-- 筛选区域 -->
            <div class="sld_screen">
                <a @click="filterSort(0)" :class="0 == indexNum ? 'btn_sort' : ''">{{ L['综合'] }}</a>
                <a @click="filterSort(1)" :class="1 == indexNum ? 'btn_sort' : ''">
                    {{ L['销量'] }}
                    <i class="iconfont" style="font-size: 12px;"></i>
                </a>
                <a @click="filterSort(2)" :class="2 == indexNum ? 'btn_sort' : ''">
                    {{ L['评论数'] }}
                    <i class="iconfont" style="font-size: 12px;"></i>
                </a>

                <!-- <div class="price_section on">
                    <div class="input_box">
                        <input type="number" class="sld_rmb" v-model="priceMin" id="priceMin"
                            @input="priceInput('priceMin',$event)">
                        <span class="line"></span>
                        <input type="number" class="sld_rmb" v-model="priceMax" id="priceMax"
                            @input="priceInput('priceMax',$event)">
                    </div>
                    <div class="ctrl clearfix">
                        <a class="fl clearVal" @click="reset">{{L['清空']}}</a>
                        <a class="fr confirm" @click="confirm">{{L['确定']}}</a>
                    </div>
                </div> -->

                <span class="sld_goods_num">{{ goodsData.page.total }}件相关商品</span>
            </div>

            <!-- 商品列表 -->
            <ul :class="{ sld_goods_list: true, skeleton_sld_goods_list: firstLoading }">
                <li v-for="({ goodsName, mainImage, wholesaleStepPrice, saleNum, productId, storeName, goodsId, storeId, cooperate, saleCooperateApplyUrl }, index) in (firstLoading ? skeletonData.data : goodsData.data)"
                    :key="index">
                    <div class="sld_img sld_img_center">
                        <router-link target="_blank" :to="`/goods/detail?productId=${productId}`">
                            <img :src="mainImage" alt="">
                        </router-link>
                    </div>
                    <p class="clearfix">
                        <span class="sld_goods_price fl">￥<em>{{ wholesaleStepPrice ?
                                Number(wholesaleStepPrice).toFixed(2) : '--'
                        }}</em></span>
                        <span class="fr">成交量&nbsp;<em>{{ saleNum }}</em></span>
                    </p>
                    <div class="sld_h32_hide">
                        <router-link class="sld_goods_name" target="_blank"
                            :to="`/goods/detail?productId=${productId}`">
                            <span v-html="goodsName"></span>
                        </router-link>
                    </div>


                    <div class="flex_row_between_center">
                        <div class="flex_row_between_center">
                            <div class="sld_vendor_name">
                                <router-link target="_blank" :to="`/store/index?vid=${storeId}`" class="sld_vendor_name"
                                    @click.stop>
                                    <span>{{ storeName }}</span>
                                    <span class="el-icon-arrow-right"></span>
                                </router-link>
                            </div>
                            <div class="coorp_already" v-if="cooperate == 4">已合作</div>
                            <div class="coorp_already" v-if="cooperate == 5">合作禁止</div>
                            <div class="coorp_already" v-if="cooperate == 3">审核中</div>
                            <div class="coorp_already" v-if="cooperate == 2">审核拒绝</div>
                        </div>

                        <div class="apply_entry" :style="{ backgroundImage: `url(${applyBg})` }"
                            @click="applyCoorpFuc(storeName, storeId, saleCooperateApplyUrl)"
                            v-if="cooperate == 1 || cooperate == 2">
                            申请合作
                        </div>
                    </div>

                    <div class="tag flex_row_start_center">
                        <div class="is_own" v-if="isOwnShop == 1">自营</div>
                    </div>
                    <div class="op_but flex_row_start_center" v-if="false">
                        <div class="but_i first flex_row_center_center"
                            @click.stop="collectGoods(defaultProductId, isFollowGoods)">
                            <div v-if="!isFollowGoods">
                                <i class="iconfont">&#xe612;</i>{{ L['收藏'] }}
                            </div>
                            <div v-else>
                                <i><img src="@/assets/goods/collection.png" class="collection"></i>{{ L['已收藏'] }}
                            </div>
                        </div>
                        <div class="but_i second flex_row_center_center"
                            @click.stop="addCart(defaultProductId, goodsId)">
                            <div>
                                <i>
                                    <img src="@/assets/goods/add_cart.png" alt="" width="22" height="21"></i>{{
                                            L['加入购物车']
                                    }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <SldCommonEmpty v-show="isEmpty" />
        </div>

        <!-- 分页 -->
        <div class="flex_row_center_center sld_pagination">
            <el-pagination @current-change="handleCurrentChange" v-model:currentPage="goodsData.page.current"
                :page-size="goodsData.page.pageSize" layout="prev, pager, next, jumper" :total="goodsData.page.total"
                :hide-on-single-page="true">
            </el-pagination>
        </div>

        <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal"
            @refreshInfo="refreshInfo" />

        <applyCoorp ref="applyCoorp" @update="applyUpdate"></applyCoorp>
    </div>

</template>
<script>
import applyCoorp from './components/applyCoorp'
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch } from 'vue';
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import GoodsListCate2 from '@/components/GoodsListCate2';
import GoodsListCate3 from '@/components/GoodsListCate3';
import SldLoginModal from "../../components/loginModal";
import SldCommonEmpty from '../../components/SldCommonEmpty'
import CategoryHeader from '@/components/CategoryHeader'
export default {
    name: 'goodsList',
    components: {
        GoodsListCate2,
        GoodsListCate3,
        SldLoginModal,
        SldCommonEmpty,
        CategoryHeader,
        applyCoorp
    },
    setup() {
        //变量------------------------------
        const firstLoading = ref(true);//是否第一次加载
        const skeletonData = reactive({ data: [] });
        const store = useStore();
        const loginModalVisibleFlag = ref(false);//登录弹框是否显示，默认不显示
        const initSkeletonData = () => {
            for (let i = 0; i < 10; i++) {
                skeletonData.data.push({
                    goodsName: '',
                    mainImage: '',
                    wholesaleStepPrice: '',
                    saleNum: '',
                    activityList: '',
                    isFollowGoods: '',
                    productId: '',
                });
            }
        }
        initSkeletonData();
        const route = useRoute()
        const router = useRouter()
        const catePath = ref([]) //导航路径
        const showIndex = ref(0) //显示flag
        const catePathName = reactive({ path: [] }) //需要在页面显示的整个分类路径
        const params = reactive({ //初始化的请求参数
            current: 1,
        })
        const { proxy } = getCurrentInstance()
        const L = proxy.$getCurLanguage();
        const goodsData = reactive({ data: [], page: {} })
        const indexNum = ref(0)
        const priceMin = ref(null)
        const priceMax = ref(null)
        const isEmpty = ref(false)
        const keyword = ref(route.query.keyword != undefined && route.query.keyword ? route.query.keyword : '');//搜索关键词
        const cate1 = reactive({ cate: {}, child: {} })
        const cate2 = reactive({ cate: {}, child: {} })
        const cate3 = reactive({ cate: {} })
        const categoryId3 = ref(route.query.categoryId != undefined && route.query.categoryId ? route.query.categoryId : '')
        const attriList = ref([])
        const attriListTemp = ref([])
        const scrollTop = ref(null)
        const priceDidSel = ref(false)
        const applyBg = require('@/assets/apply_coorperation.png')
        //方法------------------------------

        //初始化数据，进行请求-start
        const getInitData = (params) => {
            window.scrollTo(0, scrollTop.value)
            let searchParams = { ...params }
            proxy.$get('v3/goods/front/goods/list', searchParams).then(res => {
                firstLoading.value = false;
                priceDidSel.value = false
                if (res.state == 200) {
                    goodsData.data = res.data.list
                    goodsData.data.map(item =>
                        // item.wholesaleStepPrice = new Number(item.wholesaleStepPrice).toFixed(2)
                        item.saleNum = item.saleNum > 10000 ? (item.saleNum / 10000).toFixed(1) * 1 + 'w' : item.saleNum
                    )
                    isEmpty.value = res.data.list.length ? false : true
                    goodsData.page = res.data.pagination
                    showIndex.value = Number(route.query.g)
                }
            })
        }
        //初始化数据，进行请求-end


        const getCate = (e) => {
            cate1.child = e.children
            cate1.name = e.categoryName
        }



        //页数改变的方法-start
        const handlePrevCilickChange = (e) => {
            params.current = e
            getInitData(params)
        }
        const handleNextCilickChange = (e) => {
            params.current = e
            getInitData(params)
        }
        const handleCurrentChange = (e) => {
            params.current = e
            getInitData(params)
        }
        const filterSort = (index) => {
            if (indexNum.value == index) {
                return
            }

            indexNum.value = index
            params.current = 1
            scrollTop.value = document.body.scrollTop || document.documentElement.scrollTop
            let query = {}
            if (route.query.categoryId) {
                query.categoryId = route.query.categoryId
            } else if (route.query.keyword) {
                query.keyword = route.query.keyword
            } else if (route.query.storeId) {
                query.storeId = route.query.storeId

            }
            if (route.query.goodsIds) {
                query.goodsIds = route.query.goodsIds
            }
            if (route.query.brandId) {
                query.brandId = route.query.brandId
            }

            if (route.query.pid) {
                query.pid = route.query.pid
            }

            if (route.query.g) {
                query.g = route.query.g
            }

            router.push({
                path: 'list',
                query: {
                    ...query,
                    sort: indexNum.value
                }
            })
        }
        //页数改变的方法-end

        //商品是否自营-start
        const isOwnShop = ref(false)
        const filterStoreSelf = (e) => {
            isOwnShop.value = !e
            params.isSelf = isOwnShop.value ? 1 : 0;
            getInitData(params)
        }
        //商品是否自营-end

        //价格区间事件-start
        const confirm = () => {//价格区间确认按钮
            priceDidSel.value = true

            if (priceMin.value && priceMax.value) {

                priceMin.value = Math.abs(priceMin.value)
                priceMax.value = Math.abs(priceMax.value)

                if (Number(priceMin.value) > Number(priceMax.value)) {
                    let temp = priceMin.value
                    priceMin.value = priceMax.value
                    priceMax.value = temp
                    params.priceMax = priceMax.value
                    params.priceMin = priceMin.value
                    params.current = 1
                    getInitData(params)

                } else {
                    params.priceMax = Number(priceMax.value) > 999999 ? 999999 : Number(priceMax.value)
                    params.priceMin = Number(priceMin.value) < 0.01 ? 0.01 : priceMin.value
                    params.current = 1
                    getInitData(params)
                }

            } else if (priceMin.value && !priceMax.value) {
                priceMin.value = Math.abs(priceMin.value)
                if (priceMin.value.toString().split(".")[1] && priceMin.value.toString().split(".")[1].length > 2) {
                    priceMin.value = Number(priceMin.value).toFixed(2)
                }
                params.priceMin = Number(priceMin.value) < 0.01 ? 0.01 : priceMin.value
                delete params.priceMax
            } else if (!priceMin.value && priceMax.value) {

                priceMax.value = Math.abs(priceMax.value)
                if (priceMax.value.toString().split(".")[1] && priceMax.value.toString().split(".")[1].length > 2) {
                    priceMax.value = Number(priceMax.value).toFixed(2)
                }
                params.priceMax = Number(priceMax.value) > 999999 ? 999999 : Number(priceMax.value)
                delete params.priceMin
            }
        }
        const reset = () => {//价格区间清空按钮
            params.priceMax = priceMax.value = ''
            params.priceMin = priceMin.value = ''
            params.current = 1

            if (priceDidSel.value) {
                getInitData(params)
            }

            //这是 当 例如 3- 这种情况出现 无法清空，执行
            document.getElementById('priceMin').value = ''
            document.getElementById('priceMax').value = ''
        }


        const priceInput = (type, e) => {
            if (type == 'priceMin') {
                priceMin.value = e.target.value
                let low = String(e.target.value).split('.')
                if (low[1] && low[1].length >= 2) priceMin.value = Number(e.target.value).toFixed(2)

            }

            if (type == 'priceMax') {
                priceMax.value = e.target.value
                let high = String(e.target.value).split('.')
                if (high[1] && high[1].length >= 2) priceMax.value = Number(e.target.value).toFixed(2)

            }
        }
        //价格区间事件-end




        //收藏及取消收藏商品
        const collectGoods = (productId, isFollowGoods) => {
            if (store.state.loginFlag) {  //已登录
                let params = {
                    productIds: productId,
                    isCollect: !isFollowGoods
                }
                proxy.$post("v3/member/front/followProduct/edit", params).then((res) => {
                    if (res.state == 200) {
                        ElMessage.success(res.msg);
                        goodsData.data.map(goodsItem => {
                            if (goodsItem.productId == productId) {
                                goodsItem.isFollowGoods = !isFollowGoods;
                            }
                        })

                    } else {
                        ElMessage.error(res.msg);
                    }
                });
            } else {
                //未登录提示登录
                loginModalVisibleFlag.value = true;
            }
        };
        //关闭登录弹框
        const closeLoingModal = () => {
            loginModalVisibleFlag.value = false;
        };

        //属性选择--start
        const attriOption = (attributeName, attributeValue, attributeId, attributeValueId) => {//添加属性查询
            attriList.value.push(attributeValueId)
            attriListTemp.value.push({
                name: attributeName,
                value: attributeValue,
                type: 'attr',
                id: attributeId,
                valueId: attributeValueId
            })
            params.attributeInfo = attriList.value.join(',')
            proxy.$get('v3/goods/front/goods/list', params).then(res => {
                if (res.state == 200) {
                    goodsData.data = res.data.list
                    goodsData.page = res.data.pagination
                }

            })
        }

        const attriKick = (index, item) => {//删除属性查询
            attriListTemp.value.splice(index, 1)
            if (item.type == "attr") {
                attriList.value = attriList.value.filter(subitem => subitem != item.valueId)
                proxy.$refs.goodsCate3.attrSorH(item.id)
                if (attriListTemp.value.length) {
                    params.attributeInfo = attriList.value.join(',')
                    proxy.$get('v3/goods/front/goods/list', params).then(res => {
                        if (res.state == 200) {
                            goodsData.data = res.data.list
                            goodsData.page = res.data.pagination
                        }
                    })
                } else {
                    delete params.attributeInfo
                    proxy.$get('v3/goods/front/goods/list', params).then(res => {
                        if (res.state == 200) {
                            goodsData.data = res.data.list
                            goodsData.page = res.data.pagination
                        }
                    })
                }
            } else if (item.type == "brand") {
                delete params.brandIds
                proxy.$refs.goodsCate3.brandSorH()
                proxy.$get('v3/goods/front/goods/list', params).then(res => {
                    if (res.state == 200) {
                        goodsData.data = res.data.list
                        goodsData.page = res.data.pagination
                    }
                })
            }
        }

        const brandOption = (brandId, brandName) => {
            attriListTemp.value.push({
                name: '品牌',
                value: brandName,
                type: 'brand',
            })
            params.brandIds = brandId
            proxy.$get('v3/goods/front/goods/list', params).then(res => {
                goodsData.data = res.data.list
                goodsData.page = res.data.pagination
            })

        }
        //属性选择--end


        const applyCoorpFuc = (storeName, storeId, saleCooperateApplyUrl) => {

            if (!store.state.loginFlag) {
                loginModalVisibleFlag.value = true
                return
            }



            proxy.$refs.applyCoorp.setStoreInfo(storeName, storeId, saleCooperateApplyUrl)
            proxy.$refs.applyCoorp.dialogVisible = true
        }

        const applyUpdate = (storeId) => {
            goodsData.data.map(item => {
                if (item.storeId == storeId) {
                    item.cooperate = 3
                }
            })
        }

        //监听事件--start
        watchEffect(() => {
            if (route.query.keyword != undefined) {
                keyword.value = route.query.keyword;
                categoryId3.value = ""
                showIndex.value = -1
                catePathName.path = []
                params.goodsName = keyword.value
                delete params.categoryId
                getInitData(params)

            } else if (route.query.categoryId != undefined) {
                categoryId3.value = route.query.categoryId;
                keyword.value = "";
                params.categoryId = route.query.categoryId
                delete params.goodsName
                getInitData(params)
            }
        });
        watch(() => route.query.categoryId, (nv, ov) => {
            if (nv != ov) {
                categoryId3.value = nv
                attriList.value = []
                attriListTemp.value = []
                priceMin.value = priceMax.value = null
                isOwnShop.value = false
            }

        })
        watch(() => route.query.keyword, (nv, ov) => {
            if (nv != ov) {
                priceMin.value = priceMax.value = null
                isOwnShop.value = false
                delete params.priceMax
                delete params.priceMin
            }
        })
        watch(() => route.query.sort, (nv, ov) => {

            params.sort = route.query.sort
            indexNum.value = route.query.sort ? route.query.sort : 0
            getInitData(params)
        })

        const refreshInfo = () => {
            history.go(0)
        };


        //监听事件--end

        router.beforeEach((to, from) => {
            if (to.path == from.path) {
                document.body.scrollTop = scrollTop.value
            }
        })

        onMounted(() => {
            if (route.query.categoryId) {
                params.categoryId = route.query.categoryId
            }
            if (route.query.keyword) {
                params.goodsName = route.query.keyword
            }
            if (route.query.storeId) {
                params.storeId = route.query.storeId

            }
            if (route.query.goodsIds) {
                params.goodsIds = route.query.goodsIds
            }
            if (route.query.brandId) {
                params.brandIds = route.query.brandId
            }

            if (route.query.sort) {
                params.sort = route.query.sort
                indexNum.value = route.query.sort
            }
            getInitData(params)


            // setTimeout(() => {
            //     proxy.$refs.applyCoorp.dialogVisible = true
            // }, 1000)
        })



        //返回值
        return {
            applyUpdate,
            goodsData,
            handlePrevCilickChange,
            handleNextCilickChange,
            handleCurrentChange,
            L,
            catePathName,
            catePath,
            showIndex,
            indexNum,
            filterSort,
            filterStoreSelf,
            isOwnShop,
            priceMin,
            priceMax,
            confirm,
            reset,
            isEmpty,
            keyword,
            cate1,
            cate2,
            cate3,
            firstLoading,
            skeletonData,
            collectGoods,
            loginModalVisibleFlag,
            closeLoingModal,
            categoryId3,
            attriOption,
            attriList,
            attriKick,
            attriListTemp,
            brandOption,
            priceInput,
            getCate,
            applyBg,
            applyCoorpFuc,
            refreshInfo
        }
    }
}


</script>

<style lang="scss" scoped>
@import '../../style/goodsList.scss';
@import '../../style/base.scss';

input[type="checkbox"] {
    display: none;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 3px;
}

input[type="checkbox"]+label {
    background: url('../../assets/goods/unsel.png') left center no-repeat;
    padding-left: 20px;
    background-size: 12px 12px;
}

input[type="checkbox"]:checked+label {
    background-image: url('../../assets/goods/select.png');
}

.apply_entry {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 64px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    color: #fff;
    padding-left: 10px;
    cursor: pointer;
    transform: scale(0.9);

}

.coorp_already {
    padding: 0 4px;
    height: 18px;
    background: #FF701E;
    border-radius: 3px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    transform: scale(0.9);
}

.empty_page {
    flex-direction: column;
    padding: 100px;
}

.sld_pagination {
    margin-top: 30px;
    margin-bottom: 30px;
}

/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>