<template>
     <div class="apply_coorperation">
          <el-dialog width="646px" lock-scroll="false" v-model="dialogVisible" top="7vh">
               <div>
                    <div class="bd6 flex_row_between_center">
                         <span class="info15">立即代销</span>
                         <img class="icon3" src="@/assets/close.png" @click="dialogVisible = false" />
                    </div>

                    <div class="table_cell">
                         <table style="border-spacing:20px;">
                              <tr>
                                   <td class="td_title"><span><i class="ico">*</i>商品分类：</span></td>
                                   <td>

                                        <el-cascader ref="cascaderAddr" v-model="selCategorys" :options="areaDataArray"
                                             placeholder="请选择分类" :props="cascaderProps" clearable
                                             @change="casaderChange" :show-all-levels="false"></el-cascader>
                                   </td>
                                   <td>
                                        <span class="errMsg">{{ errMsg.casader }}</span>
                                   </td>
                              </tr>
                              <tr v-if="!retailPrice">
                                   <td class="td_title"><span><i class="ico">*</i>销售价：</span></td>
                                   <td>
                                        <div class="flex_row_start_center on_sale_price">
                                             <span>在供货价的基础上增加</span>
                                             <el-input type="number" placeholder="请输入金额" v-model="addPrice"
                                                  @input="handleInput('addPrice')">
                                             </el-input>
                                             <span style="margin-left:10px;">元</span>

                                        </div>
                                        <div class="on_sale_tip">商品为多规格商品时，该操作对所有sku均有效。</div>
                                   </td>
                                   <td>
                                        <span class="errMsg">{{ errMsg.addPrice }}</span>
                                   </td>
                              </tr>

                              <tr>
                                   <td class="td_title"><span><i class="ico">*</i>运费类型：</span></td>
                                   <td>
                                        <el-radio-group v-model="feeType" class="flex_row_start_center"
                                             @change="radioChange">
                                             <el-radio :label="1">固定运费</el-radio>
                                             <el-radio :label="2">运费模板</el-radio>
                                        </el-radio-group>
                                   </td>
                              </tr>

                              <tr v-if="feeType == 1">
                                   <td class="td_title"><span><i class="ico">*</i>固定运费：</span></td>

                                   <td>
                                        <el-input placeholder="请输入固定运费" v-model="fixedFee"
                                             @input="handleInput('fixedFee')" type="number">
                                        </el-input>
                                        <span class="errMsg" style="margin-left:10px;">{{ errMsg.fixedFee }}</span>
                                   </td>

                              </tr>

                              <tr v-if="feeType == 2">
                                   <td class="td_title"><span><i class="ico">*</i>运费模板：</span></td>

                                   <td class="el_select">
                                        <el-select v-model="freightId" class="m-2" placeholder="选择运费模板"
                                             @change="selectChange">
                                             <el-option v-for="(item, index) in freightList" :key="index"
                                                  :label="item.templateName" :value="item.freightTemplateId" />
                                        </el-select>
                                        <span class="errMsg" style="margin-left:10px;">{{ errMsg.fixedFee }}</span>
                                   </td>

                              </tr>


                         </table>
                    </div>

                    <div class="main_down flex_row_end_center">
                         <div class="but1" @click="dialogVisible = false">取消</div>
                         <div class="but2" @click="confirmApi">确定</div>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
import { getCurrentInstance, reactive, ref, onMounted, watch } from "vue";
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
export default {
     props: ['goodsId', 'productId', 'retailPrice', 'chosenSpecList'],
     setup(props, { emit }) {
          const store = useStore()
          const dialogVisible = ref(false)
          const contactName = ref('')
          const contactPhone = ref('')
          const uploading = ref(false)
          const loadState = ref('do')
          const { proxy } = getCurrentInstance()
          const errMsg = reactive({ contactName: '', contactPhone: '', file: '' })
          const storeName = ref('')
          const storeId = ref(null)
          const memberInfo = store.state.memberInfo
          const feeType = ref(1)
          const cascaderProps = { label: "categoryName", value: "categoryId" };
          const areaDataArray = ref([])
          const selCategorys = ref([])
          const addPrice = ref('')
          const fixedFee = ref('')
          const freightList = ref([])
          const freightId = ref('')
          // 上传文件

          const handleInput = (type) => {
               errMsg[type] = ''

               if (type == 'addPrice' && addPrice.value) {
                    addPrice.value = format(addPrice.value)
               } else if (type == 'fixedFee' && fixedFee.value) {
                    fixedFee.value = format(fixedFee.value)
               }
          }

          const option = {

          }

          const radioChange = () => {
               errMsg.fixedFee = ''
          }

          const checkInput = () => {
               if (!selCategorys.value.length) {
                    errMsg.casader = '请选择分类'
                    return false
               }

               if (!props.retailPrice) {
                    if (!addPrice.value) {
                         errMsg.addPrice = '请输入金额'
                         return false
                    }
               }


               if (feeType.value == 1 && !fixedFee.value) {
                    errMsg.fixedFee = '请输入固定运费'
                    return false
               }


               if (feeType.value == 2 && !freightId.value) {
                    errMsg.fixedFee = '请选择运费模板'
                    return false
               }



               return true
          }

          const confirmApi = () => {
               if (!checkInput()) {
                    return
               }

               let param = {
                    categoryId: selCategorys.value[2],
                    importState: true,
                    supplierGoodsIds: props.goodsId,
               }


               if (props.chosenSpecList.length) {
                    let productIds = props.chosenSpecList.map(i => i.productId)
                    param.supplierProductIds = productIds.join(',')
               } else {
                    param.supplierProductIds = props.productId

               }

               if (!props.retailPrice) {
                    param.addPrice = addPrice.value
               }

               if (feeType.value == 1) {
                    param.freightFee = fixedFee.value
               } else {
                    param.freightId = freightId.value
               }

               proxy.$post('v3/goods/front/goods/goodsImport', param).then(res => {
                    if (res.state == 200) {
                         if (res.data.state == 200) {
                              dialogVisible.value = false

                              ElMessage.success(res.data.msg)
                              emit('update')
                         } else {
                              ElMessage.error(res.data.msg)

                         }

                    } else {
                         ElMessage.error(res.data.msg)
                    }
               })
          }
          const listIndex = ref(-1)


          const getCateTree = () => {
               proxy.$get('v3/goods/front/goods/getCateTree').then(res => {
                    if (res.state == 200) {
                         areaDataArray.value = res.data.data
                    }
               })
          }


          const getFreightList = () => {
               proxy.$get('v3/goods/front/goods/freightList').then(res => {
                    if (res.state == 200) {
                         freightList.value = res.data.data
                    }
               })
          }

          const casaderChange = (e) => {
               errMsg.casader = ''
               console.log(selCategorys.value, 'ppp')
               console.log(e)
          }


          const selectChange = (e) => {
               errMsg.fixedFee = ''
          }



          const setStoreInfo = (storeNameA, storeIdA) => {
               console.log(storeNameA, storeIdA)
               storeId.value = storeIdA
               storeName.value = storeNameA
          }


          watch(dialogVisible, () => {

          })




          const format = (value) => {

               let value1 = (value > 0 ? value : 0 - value).toString()

               let high = String(value1).split('.')
               if (high[1] && high[1].length >= 2) value1 = Number(value1).toFixed(2)

               return value1.slice(0, 6)
          }




          onMounted(() => {
               getCateTree()
               getFreightList()
               dialogVisible.value = false

          })




          return {
               selectChange,
               freightList,
               fixedFee,
               addPrice,
               casaderChange,
               dialogVisible,
               contactName,
               contactPhone,
               loadState,
               handleInput,
               errMsg,
               confirmApi,
               setStoreInfo,
               feeType,
               cascaderProps,
               areaDataArray,
               selCategorys,
               freightId,
               radioChange
          }
     }
}
</script>


<style lang="scss">
/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
}

input[type="number"] {
     -moz-appearance: textfield;
}

.el-dialog__body {
     padding: 0 !important;
}

.el-input__inner {
     height: 30px;
     line-height: 30px;
}

table {
     border-collapse: separate;
     /* border-collapse: collapse; */
}

.table_cell {
     .el-cascader {
          width: 350px;
     }

     .el-cascader .el-input.is-focus .el-input__inner {
          border-color: $colorMain;
     }

     .in-active-path {
          color: $colorMain;
     }
}


.el_select {
     .el-input__suffix {
          top: -5px;
     }
}



.apply_coorperation {
     .el-dialog .el-dialog__header {
          display: none;
     }

     .on_sale_price {
          span {
               white-space: nowrap;
               margin-right: 10px;
          }

          input {
               width: 200px;
          }


     }

     .on_sale_tip {
          margin-top: 5px;
          color: rgba(0, 0, 0, .45);
          font-size: 13px;
     }


     .el-input__inner:focus {
          outline: 0;
          border-color: $colorMain;
     }

     .td_title {
          text-align: right;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
     }

     .errMsg {
          font-size: 12px;
          margin-top: 5px;
          color: #E2231A;
     }

     .table_cell {
          padding-left: 20px;
     }

     .ico {
          color: #e2231a;
          font-style: normal;
     }

     .d_model {
          width: 78px;
          height: 28px;
          background: #E2231A;
          border-radius: 6px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28px;
          text-align: center;
          cursor: pointer;
          margin-bottom: 10px;
     }

     .outer2 {
          z-index: 9;
          height: 192px;
          border: 1px solid rgba(217, 217, 217, 1);
          background-color: rgba(255, 255, 255, 1);
          width: 384px;
          justify-content: center;
          align-items: center;
          display: flex;

          .mod2 {
               z-index: auto;

               .img1 {
                    z-index: 10;
                    width: 50px;
                    height: 50px;
                    align-self: center;
               }

               .layer1 {
                    z-index: 16;
                    width: 182px;
                    height: 22px;
                    overflow-wrap: break-word;
                    text-align: left;
                    white-space: nowrap;
                    margin-top: 30px;
                    font-size: 0;

                    .word2 {
                         font-size: 14px;
                         font-family: PingFangSC-Regular;
                         color: rgba(153, 153, 153, 1);
                         line-height: 22px;
                         overflow: hidden;
                         text-overflow: ellipsis;
                    }

                    .word3 {
                         font-size: 14px;
                         font-family: PingFangSC-Regular;
                         color: rgba(40, 95, 222, 1);
                         line-height: 22px;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         cursor: pointer;
                    }
               }

               #upload {
                    display: none;
               }

               .layer22 {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 10px;
               }
          }
     }

     .layer_success {
          .success_excel {
               height: 62px;
               border-radius: 2px;
               border: 1px solid rgba(217, 217, 217, 1);
               background-color: rgba(255, 255, 255, 1);
               width: 260px;
               padding-left: 10px;

               .left {
                    img {
                         width: 42px;
                         height: 42px;
                    }

                    span {
                         margin-left: 15px;
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #333333;
                    }
               }

               .right {
                    display: flex;
                    align-self: flex-start;
                    padding-top: 5px;
                    padding-right: 5px;

                    .right_con {
                         cursor: pointer;
                         width: 18px;
                         height: 18px;
                         background: #999;
                         border-radius: 50%;
                         text-align: center;
                         line-height: 18px;
                         align-self: flex-start;

                         .el-icon-delete {
                              color: #fff;
                         }
                    }

               }



          }

          .success_img {
               position: relative;
               width: 181px;
               height: 181px;
               background: #FFFFFF;
               border-radius: 2px;
               border: 1px solid #D9D9D9;

               .img_con {
                    width: 179px;
                    height: 179px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
               }

               .del_con {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
               }
          }
     }

     .bd6 {
          background-color: rgba(226, 35, 26, 1);
          height: 40px;
          padding: 0 20px;

          .info15 {
               color: rgba(255, 255, 255, 1);
               font-size: 16px;
          }

          .icon3 {
               width: 16px;
               height: 16px;
               cursor: pointer;
          }
     }

     .main_down {
          border-top: 1px solid #F2F2F2;
          height: 67px;
          padding: 0 20px;
          margin-top: 30px;

          .but1 {
               width: 78px;
               height: 36px;
               background: #EEEEEE;
               border-radius: 6px;
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #666262;
               line-height: 36px;
               text-align: center;
               cursor: pointer;
          }

          .but2 {
               margin-left: 20px;
               width: 78px;
               height: 36px;
               background: #E2231A;
               border-radius: 6px;
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #FFFFFF;
               line-height: 36px;
               text-align: center;
               cursor: pointer;
          }
     }
}
</style>